.dday_container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    text-align: center;
    background-color: #F7F7F7;
    margin-bottom: 70px;
}

.dday_img{
    width: 100vw;
    touch-action: none; /* 이미지 터치 동작 방지 */
    user-select: none;  /* 텍스트 선택 방지 */
    pointer-events: none; /* 이미지 클릭 이벤트 방지 */
}

@media screen and (max-height: 700px) {
    .dday_img{
        width: 100vw;
    }

    .dday_box{
        padding: 5% 0;
    }
}

@media screen and (min-width: 450px) {
    .dday_container{
        align-items: center;
    }

    .dday_img{
        width: 400px;
    }
}

.text_box {
    display: flex;
    width: 270px;
    justify-content: space-between;
    padding-bottom: 25px;
    align-items: center;
    font-size: 14px;
}

.dday_box{
    width: 100vw;
    height: 147px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px 0;
}

.date_container_wrap{
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #F7F7F7;
}

.date_container{
    display: flex;
    width: 350px;
    justify-content: space-around;
    padding: 5px 5px;
}

.date_wrap{
    color: #313131;
    font-family: "Edensor", sans-serif;
}

.date_box{
    display: flex;
    justify-content: center;
    width: 68px;
    height: 80px;
    font-size: 18px;
    align-items: center;
    background-color: #fff; 
    border-radius: 5px;
    box-shadow: 2px 4px 4px rgb(0, 0, 0, 0.15);
}

.date_wrap p {
    font-size: 12px;
    padding-top: 10px;
}

.modal_item_wrap{
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal_item_wrap input{
    width: 207px;
    height: 29px;
    background-color: #FFFFFF;
    border: solid .4px #313131;
    border-radius: 4px;
    padding: 0 5px;
}


.modal_item_box{
    display: flex;
    justify-content: space-between;
    width: 217px;
}

.modal_item_box button{
    width: 107px;
    height: 29px;
    font-size: 11px;
    background-color: #FFFFFF;
    border: solid .4px #313131;
    border-radius: 4px;
}

.modal_item_box button:hover{
    cursor: pointer;
}

.modal_item_box button:focus{
    cursor: pointer;
    background-color: #DCDCDC;
}

.modal_item_box button.selected {
    background-color: #DCDCDC;
}

.modal_item_title{
    text-align: left;
    font-size: 9px;
}

