
@font-face {
  font-family: 'Autography';
  src: url('./font/Autography.otf'); 
  font-display: block;
}

@font-face {
  font-family: 'BLOVERLY';
  src: url('./font/BLOVERLY.ttf');
  font-display: block;
}

@font-face {
  font-family: 'SCDream1';
  src: url('./font/SCDream1.otf');
  font-display: block;
}


@font-face {
  font-family: 'SCDream2';
  src: url('./font/SCDream2.otf');
  font-display: block;
}

@font-face {
  font-family: 'SCDream3';
  src: url('./font/SCDream3.otf');
  font-display: block;
}


@font-face {
  font-family: 'SCDream4';
  src: url('./font/SCDream4.otf');
  font-display: block;
}

@font-face {
  font-family: 'SCDream5';
  src: url('./font/SCDream5.otf');
  font-display: block;
}

@font-face {
  font-family: 'QESTERO';
  src: url('./font/QESTERO-Regular.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Edensor';
  src: url('./font/Edensor-FREE.otf');
  font-display: block;
}

@font-face {
  font-family: 'BestermindReqular';
  src: url('./font/BestermindRegular.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Milchella';
  src: url('./font/Milchella-Regular.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Cherolina';
  src: url('./font/Cherolina.ttf');
}

@font-face {
  font-family: 'Ethereal';
  src: url('./font/EtherealDemoFont-ExtraLight.otf');
  font-display: block;
}

@font-face {
  font-family: 'JejuMyeongjo';
  src: url('./font/JejuMyeongjoOTF.otf');
  font-display: block;
}

@font-face {
  font-family: 'Vivaldii';
  src: url('./font/VIVALDII.TTF');
  font-display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
