.gallery_card_container{
    width: 360px;
    /* height: 760px; */
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    /* overflow: scroll; */
    justify-content: center;
}

.card_photo{
    width: 157px;
    height: 235px;
    object-fit: cover;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none; 
    touch-action: manipulation;
}

.yarl__toolbar{
    padding: 5px !important;
}

.yarl__slide_image{
    height: 510px;
}

.yarl__slide_current{
    padding-top: 50px !important;
}

.yarl__slide_captions_container{
    background-color: rgba(255, 255, 255, 0.9) !important;
    align-items: center;
    padding: 20px !important;
    text-align: center;
}

.yarl__slide_title {
    color: rgb(49, 49, 49) !important;
    font-family: "SCDream3", sans-serif !important;
    font-size: 14px !important;
}

.yarl__slide_description{
    color: rgb(49, 49, 49) !important;
    font-family: "SCDream3", sans-serif !important;
}

.yarl__thumbnails_thumbnail{
    background-color: rgba(198, 198, 198, 0.2) !important;
    border-radius: 2px
}

.yarl__button{
    color: rgb(152, 152, 152) !important;
    filter: none !important;
    padding: 5px !important;
}

.yarl__icon{
    width: 20px !important;
    height: 20px !important;
}