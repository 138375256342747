*{
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;

}

body{
  font-size: 100%;
  word-break: break-all;
  font-family: "SCDream4";
  touch-action: manipulation; /* 모든 확대 동작 차단 */
  overscroll-behavior: none; /* 추가 확대 방지 */
  -webkit-touch-callout: none !important;
}

div{
  display: block;
  unicode-bidi: isolate;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h1{
  display: block;
  margin: 0.5em;
}

input {
  border: none;  
  background-color: transparent;
  cursor: pointer;
}

input:focus {
  outline:none;
}

textarea:focus {
  outline:none;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-name: fadeOut;
	animation-duration: 3s; 

  z-index: 9999; 
  display: flex;
  justify-content: center;
  align-items: center;
} */

p{
  white-space: pre-line;
  margin-bottom: 0 !important;
}
@keyframes move {
	from {
		left: 0;
	}
	to {
		left: 300px;
	}
}

/* 흰색에서 투명으로 바뀌는 애니메이션 */
@keyframes fadeOut {
  0% {
    background: rgba(255, 255, 255, 0.9); /* 초기 상태: 반투명 흰색 */
  }
  100% {
    background: rgba(255, 255, 255, 0); /* 최종 상태: 완전히 투명 */
  }
}


.overlay-text{
  color: rgb(42, 42, 42);
  font-size: 1.2em;
  text-align: center;
  width: 90%;
  line-height: 1.4em;
  font-weight: bold;
  white-space: pre-line;
  animation: fadeInText 4s 0s ease-out forwards, fadeout 5s 3s forwards;
  display: inline-block;

  animation-delay: 1s;
  animation: fadeout 4s;
  -moz-animation: fadeout 3s; /* Firefox */
  -webkit-animation: fadeout 3s; /* Safari and Chrome */
  -o-animation: fadeout 3s; /* Opera */
  animation-fill-mode: forwards;
}

@keyframes fadeInText {
  100% {
      opacity: 1;
  }
}
@keyframes fadeout {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}


@keyframes slideDown {
  0% {
    background: rgba(168, 205, 235, 0.5); /* 초기 상태: 투명한 하늘색 */
    background-size: 100% 200%; /* 배경의 크기를 조절하여 아래로 확장 */
    background-position: top; /* 위에서 시작하여 아래로 내려옴 */
  }
  100% {
    background: rgba(168, 205, 235, 0.2);  /* 투명한 하늘색 */
    background-size: 100% 200%; /* 배경의 크기를 조절하여 아래로 확장 */
    background-position: top; /* 위에서 시작하여 아래로 내려옴 */
  }
}

/* button{
  color: black !important;
} */