.fall_main_container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-around;
}

.main_text_box{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main_fall{
    height: 60%;
    object-fit: contain;
    z-index: 300;
}


 /* height가 800px 이하일 때 너비 */
/* @media (max-height: 800px) {
    .main_fall {
        width: 290px;
    }
} */