.location_container{
    display: block;
    text-align: center;
    align-items: center;
}

.location_box{
    display: flex;
    flex-direction: column;
    font-weight: 300;
    align-items: center;
    text-align: center;
}

.address_info {
    width: 270px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SCDream3';
}

.navi_box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1em 0;
}

.navi_btn {
    width: 100px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    margin: 0 8px;
}

.navi_btn:hover {
    cursor: pointer;
}

.navi_logo_img{
    width: 19px;
    margin-right: 4px;
}