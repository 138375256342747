.bubble_container {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin: 0.5em 0;
}

.bubble_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.interview_q {
    color: #313131;
    font-size: 12px;
    padding-top: 31px;
    white-space: pre-line;
    line-height: 18px;
    opacity: 0; /* 처음에는 투명하게 설정 */
    animation: fadeInUp 2s ease-in-out forwards;
    animation-delay: 1s; /* 1초 지연 후 애니메이션 시작 */
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.left {
    text-align: left;
}

.right {
    text-align: right;
    transform: translateY(-20px);
} 

.bubble_box .name {
    font-size: 10px;
    font-weight: 400;
}

.bubble_wrapper {
    position: relative;
    display: inline-block;
}

.bubble_left, .bubble_right {
    width: 142px;
}

.bubble_text {
    font-size: 13px; 
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    white-space: nowrap;
    /* font-family: SCDream3; */
}

.left_text {
    left: 42px; /* 왼쪽 말풍선의 텍스트는 왼쪽에 위치 */
    text-align: left;
}

.right_text {
    right: 42px; /* 오른쪽 말풍선의 텍스트는 오른쪽에 위치 */
    text-align: right;
}

.noti5{
    width: 26px;
    position: absolute;
    transform: translate(-50%, 90%);
}

.noti_heart{
    width: 50px;
    margin-top: 2rem;
}

.noti_heart.vibration {
    animation: vibration 0.13s infinite;
    animation-iteration-count: 40; 
}

@keyframes vibration {
from {
    transform: rotate(5deg);
}
to {
    transform: rotate(-5deg);
}
}