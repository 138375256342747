.account_container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    text-align: center;
    align-items: center;
    font-family: "SCDream4", sans-serif;
}


.account_card {
    width: 285px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-shadow: 2px 4px 4px rgb(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-bottom: 15px;
}

.account_name {
    display: flex;
    font-size: 13px;
    padding-bottom: 8px;
}

.account_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.account_box span{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account_box span:nth-child(1){
    /* min-width: 170px; */
    line-height: 1.1em;
}

.copy_img{
    width: 11px;
    height: 11px;
    margin-left: 6px;
}

.kakaopay_img{
    width: 42px;
}