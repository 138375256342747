.main_container{
    /* position: relative; */
    width: 100vw;
    height: 100vh;
    font-family: "Edensor", sans-serif;
    font-size: 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main_name_box {
    display: flex;
    justify-content: space-between;
    min-width: 327px;
    margin: 10px auto;
    opacity: 0; 
    animation: fadeInUp 2s ease-in-out forwards; 
    z-index: 1000;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.main_date_box{
    display: flex;
    justify-content: center;
    animation: fadeInUp 4s ease-in-out forwards; 
    margin: 5px auto;
}


.main_img {
    width: 353px;
    padding: 2em 0;
    z-index: -1;
    max-width: 360px;
    touch-action: none; /* 이미지 터치 동작 방지 */
    user-select: none;  /* 텍스트 선택 방지 */
    pointer-events: none; /* 이미지 클릭 이벤트 방지 */
}

/* 태블릿 디바이스 (가로 해상도가 768px 보다 큰 화면에 적용)  */
@media (max-width: 340px) {
    .main_img{
        width: 310px;
    }
} 

@media (min-width: 450px) {
    .main_img{
        padding: 0.8em 0;
    }
} 

.main_text {
    position: absolute;
    top: 47%;
    left: 51%; 
    transform: translate(-48%, -165%); 
    z-index: 100;
    max-width: 100%;
}

.main_text_bottom {
    position: absolute;
    top: 55%;
    left: 50%; 
    transform: translate(-50%, 50%); 
    max-width: 100%;
    z-index: 100;
}

.svg_text.svg_text7{
    z-index: 400 !important;
}

.svg_text.svg_text8{
    line-height: 1; 
    height: 10px;
}