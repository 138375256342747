#title {
    margin: 10px auto;
    font-size: 2em;
    border: 1px solid;
    padding: 10px;
    width: 200px;
    border-radius: 50%;
    text-align: center;
}

.arrow_btn{
    width: 17px;
    height: 17px;
    background-position: center;
    background-size: cover;
    margin: auto 5px;
}

#pre{
    background-image: url('../asset/black_pre.png');
}

#next{
    background-image: url('../asset/black_next.png');
}

.spring_h3_title {
    /* color: #FF8A83;   */
    font-family: "Edensor", sans-serif;
    font-size: 30px;
    font-weight: normal;
}

.line_img{
    width: 280px;
    padding: 75px 0;
}

.basic_column_container{
    /* padding: 75px 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.p1_score_14 {
    padding: 31px 0;
    font-size: 14px;
    color: #313131;
    font-family: "SCDream4", sans-serif;
}

.p1_score_14 {
    padding: 31px 0;
    font-size: 14px;
    color: #313131;
}

.p1_score_12{
    color: #313131;
    font-family: "SCDream3", sans-serif;
    white-space: pre-line;
    line-height: 18px;
    font-size: 13px;
}

.copy_img:hover {
    cursor: pointer;
}

.kakaopay_img:hover {
    cursor: pointer;
}

.x_img{
    width: 11px;
    height: 11px;
    padding: 2px;
}

.x_img:hover{
    cursor: pointer;
}

.my_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(106, 78, 78, 0.5); */
    /* background-color: rgba(0, 0, 0, 0.5);  */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.accout_container{
    padding-bottom: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.mySwiper {
    touch-action: pan-y; 
}

.mySwiper img {
    touch-action: none; /* 이미지 터치 동작 방지 */
    user-select: none;  /* 텍스트 선택 방지 */
    pointer-events: none; /* 이미지 클릭 이벤트 방지 */
}

.swiper-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

.swiper {
    height: 600px;
}

.info_swiper .swiper {
    height: fit-content;
    max-width: 500px;
}

.gallery_image {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 1;
}

.gallery_image.hidden {
    opacity: 0;
    transform: translateX(-100%); /* Slide out to the left */
}

.gallery_image.showing {
    opacity: 1;
    transform: translateX(0); /* Slide into view */
}