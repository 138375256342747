.summer_main_container{
    position: relative !important;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

@media screen and (min-width: 380px) {
    .summer_main_container {
        width: auto;
        height: 100vh;
        align-items: center;
    }

    .main_summer {
        height: 100vh;
    }
}

.main_summer_img{
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    overflow-y: hidden;
    max-width: 450px;
    -webkit-touch-callout: none;
    touch-action: none; /* 이미지 터치 동작 방지 */
    user-select: none;  /* 텍스트 선택 방지 */
    pointer-events: none; /* 이미지 클릭 이벤트 방지 */
}

.welcome_summer{
    position: absolute;
    text-align: center;
    width: 100vw;
    top: 10%;
    line-height: 30px;
    font-family: Milchella;
}

.main_date_box_summer{
    position: absolute;
    text-align: center;
    width: 100vw;
    bottom: 8%;
    line-height: 30px;
    font-family: Milchella;
    color: #ffffff;
}

.main_text_summer {
    position: absolute;
    bottom: 24%;
    left:  51%; 
    transform: translate(-32%, 50%); 
    z-index: 100;
    max-width: 100%;
}

.main_text_summer_bottom {
    position: absolute;
    bottom: 15%;
    left: 51%; 
    transform: translate(-48%, 50%); 
    z-index: 100;
    max-width: 100%;
}