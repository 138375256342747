.gallery_container {
    /* position: relative; */
    background-color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.p1_score_14 {
    padding: 31px 0;
    font-size: 14px;
    color: #313131;
}

.p1_score_13{
    font-size: 13px;
    color: #313131;
    font-family: "SCDream3", sans-serif;
    white-space: pre-line;
    line-height: 18px;
}