.svg_text_summber { 
    stroke-width: 0.5px;  
    stroke: var(--svg-fill-color, #ffffff);
    fill: transparent;
    stroke-dashoffset: 750;  
    stroke-dasharray: 750; 
    animation: stroke 3s linear; 
    animation-fill-mode: forwards;  // 애니메이션이 끝난 후 그 지점에 그대로 있습니다.
    z-index: 100;
}

@keyframes stroke { 
    0% { 
      stroke-dashoffset: 750;  
    } 
    90% {
      fill: var(--svg-fill-color, #ffffff); // CSS 변수 사용
    }
    100% { 
      stroke-dashoffset: 0;    
      fill: var(--svg-fill-color, #ffffff); // CSS 변수 사용
    } 
  }