.letter_container{
    text-align: center;
    padding: 2em 0;
}

.title{
    font-size: 17px;
    color: #FF8A83;  
    font-weight: 400;
    padding-bottom: 60px;
}

.body{
    font-size: 13px;
    color: #313131;
    white-space: pre-line;
    line-height: 25px;
}

.body1{
    padding-bottom: 20px;
    font-family: "SCDream3", sans-serif;
}


.body2{
    padding-bottom: 20px;
    font-family: "SCDream3", sans-serif;
}
