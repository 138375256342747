.guestBook_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    max-width: 500px;
    min-width: 270px;
}

.cake_container {
    position: relative; 
    width: 100vw;
    max-width: 500px;
    min-width: 270px;
    text-align: center;
}

.guestBook_info{
    position: absolute;
    top: 10%;
    font-size: 15px;
    text-align: center;
    font-family: SCDream3;
}
.guest_back_img {
    position: absolute;
    width: 100vw;
    max-width: 500px;
    min-width: 270px;
    z-index: 0;
    height: auto;
}

.guest_cake_img {
    width: 100vw;
    max-width: 450px;
    min-width: 270px;
    height: auto;
    z-index: 0;
}

.guest_time_info {
    position: absolute;
    top: 17%;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: SCDream3;
}

.cherry_img {
    position: absolute;
    z-index: 1000;
    width: 40px;
}

@media screen and (max-width: 340px){
    .cherry_img {
        width: 30px;
        transform: translateY(10px);
    }
} 

.cherry_img:hover{
    cursor: pointer;
}

#cherry_0 {
    top: 20%;
    left: 29%;
}

#cherry_1 {
    top: 17%;
    left: 56%;
}

#cherry_2 {
    top: 22%;
    left: 73%;
}

#cherry_3 {
    top: 35%;
    left: 76%;
}

#cherry_4 {
    top: 47%;
    left: 70%;
}

#cherry_5 {
    top: 56%;
    left: 55%;
}

#cherry_6 {
    top: 53%;
    left: 39%;
}

#cherry_7 {
    top: 46%;
    left: 25%;
}

#cherry_8 {
    top: 37%;
    left: 15%;
}

#cherry_9 {
    top: 27%;
    left: 14%;
}

.back_button {
    position: absolute;
    top: 2%;
    right: 5%;
    padding: 0px 3px;
    cursor: pointer;
    z-index: 1500;
}

.back_button:hover{
    cursor: pointer;
}

.back_button:focus{
    cursor: pointer;
}


.guestBook_export:hover{
    cursor: pointer;
}

:root {
    --swiper-navigation-size: 20px !important; 
}

.swiper-button-prev,
.swiper-button-next {
    width: var(--swiper-navigation-size);
    background-color: #e6e6e6;
    opacity: 0.5;
    padding: 15px 5px;
    border-radius: 20px;
    color: rgb(0, 0, 0) !important;
}

.swiper-pagination-bullet-active{
    background-color: #2b2b2b !important;
}

.swiper-wrapper{
    align-items: center;
}